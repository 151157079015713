<template>
  <div class="flex flex-pack-center">
    <el-form
      v-if="ruleForm"
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="130px"
    >
      <el-form-item label="工艺名称" prop="name" required>
        <el-input v-model="ruleForm.name"></el-input>
      </el-form-item>
      <el-form-item label="衡量工艺的指标" prop="quota.0.name" :rules="rules.quota">
        <el-input v-model="ruleForm.quota[0].name"></el-input>
      </el-form-item>
      <el-form-item label="工艺指标的描述" prop="quota.0.value" :rules="rules.quota">
        <el-input v-model="ruleForm.quota[0].value"></el-input>
      </el-form-item>
      <el-form-item label="主要工艺" prop="primary">
        <el-checkbox v-model="ruleForm.primary"></el-checkbox>
      </el-form-item>
      <div style="text-align:center;">
        <el-button type="primary" @click="save">立即创建</el-button>
        <el-button @click="cancel">取消</el-button>
      </div>
    </el-form>
  </div>
</template>
<script>
import cloneDeep from "lodash/cloneDeep";
import { getFactoryMakeAbility } from "@/service/company"; 
export default {
  components: {},
  data() {
    return {
      ruleForm: {
        id: (new Date()).getTime()*-1,
        name: null,
        primary: false,
        quota: [{ id: (new Date()).getTime()*-1, name: "",type:3, value: "" }],
      },
      rules: {
        name: [{ required: true, message: '请输入工艺名称', trigger: 'blur' }],
        quota:[
          {
            required: true,
            validator: (rule, value, callback) => {
              let _idx = rule.field.split(".")[1];
              let field = this.ruleForm.quota[_idx];
              if (field.required==false || (value && value.length > 0)) {
                callback();
              } else {
                callback(new Error(`必须填写!`));
              }
            },
            trigger: "blur"
          }
        ]
      },
    };
  },
  methods: {
    valid() {
      return new Promise((resolve, reject) => {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            resolve(true);
          } else {
            return reject();
          }
        });
      });
    },
    save() {
      this.valid()
        .then(() => {
          console.log(this.ruleForm);
          this.$emit("change", this.ruleForm);
        }).catch((err) => {
          console.log(err);
          if (err && err.message) this.$message.error(err.message);
        });
    },
    cancel(){
      this.$emit("cancel");
    }
  },
};
</script>
<style scoped>
.el-form-item {
  margin-bottom: 20px !important;
}
</style>