<template>
  <div class="zt-block">
    <div class="zt-block-head">奖项证书</div>
    <div class="zt-block-content">
      <UploadImgs v-model="hbs" prefix='comp/aptitude/prize' :limit=10></UploadImgs>
    </div>
  </div>
</template>
<script>
import cloneDeep from 'lodash/cloneDeep';
import UploadImgs from '@/components/upload/UploadImgs.vue';
export default {
  components: {
      UploadImgs
  },
  model: {
    prop: "relationship",
    event: "change"
  },
  props: ["relationship"],
  data() {
    return {
      hbs: this.relationship
    };
  },
  watch: {
    "hbs":{
      handler(newName, oldName) {
        this.$emit("change", this.hbs);
      },
      deep:true
    }
  },
  async created() {
    // if (this.relationship && this.relationship.length > 0) {
    //   let logos =  cloneDeep(this.relationship);
    //   this.logos.reduce((pre, cur) => {
    //     pre.push(cur.logo);
    //     return pre;
    //   }, this.hbs);
    // }
  },
  methods: {}
};
</script>
<style scoped>
</style>