<template>
  <div v-if="comp && aptitude" class="zt-page-content">
    <!-- 状态 -->
    <State
      :score="aptitude_score"
      :stat="aptitude_stat"
      :msg="aptitude_msg"
    ></State>
    <!-- 服务能力 -->
    <Service
      v-model="aptitude.ability_service"
      :compType="comp.comp_type"
      ref="service"
    ></Service>
    <!-- 现状 -->
    <Xz v-model="aptitude.stat" :compType="comp.comp_type" ref="xz"></Xz>
    <Contact v-model="aptitude.contact" ref="contact"></Contact>
    <!-- 设计公司 -->
    <!-- <Desgin v-if="comp.comp_type==2" ref="desgin"></Desgin> -->
    <template v-if="comp.comp_type == 2">
      <!-- 历史案例 -->
      <Cases v-model="aptitude.case" ref="case"></Cases>
      <!-- 品牌历程 -->
      <BrandLcs v-model="aptitude.brand" ref="brandlcs"></BrandLcs>
      <!-- 团队展示 -->
      <Teams v-model="aptitude.team" ref="team"></Teams>
      <!-- 奖项证书 -->
      <Prize v-model="aptitude.prize" ref="prize"></Prize>
      <!-- 合作伙伴 -->
      <Hzhb v-model="aptitude.relationship" ref="relationship"></Hzhb>
    </template>
    <!-- 工厂 -->
    <template v-if="comp.comp_type == 1">
      <!-- <Factory v-if="comp.comp_type==1" v-model="aptitude" ref="factory"></Factory> -->
      <!-- 相关资料 -->
      <Ziliao v-model="aptitude.relation" ref="ziliao"></Ziliao>
      <!-- 实地认证 -->
      <Sdrz :rz="aptitude.real_check"></Sdrz>
    </template>

    <!-- 销售公司 -->
    <!-- <Sale v-if="comp.comp_type==3" ref="sale"></Sale> -->
    <template v-if="comp.comp_type == 3">
      <Stores v-model="aptitude.store" ref="store"></Stores>
      <Brands v-model="aptitude.brand" ref="brand"></Brands>
    </template>

    <div class="zt-block">
      <div class="zt-block-content" style="text-align: center">
        <el-button size="small" type="primary" @click="cancel">取消</el-button>
        <!-- <el-button size="small" type="primary" @click="reset">重置</el-button> -->
        <el-button size="small" type="primary" @click="save">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getMyCompAptitude,
  submitDesignAptitude,
  submitFactoryAptitude,
  submitSaleAptitude,
} from "@/service/company";
import { getOrgidCapacity, updateOrgidCapacity } from "@/service/mes";
import State from "./State";
import Service from "./Service";
import Xz from "./Xz";
import Contact from "./Contact";
import Cases from "./desgin/Cases";
import BrandLcs from "./desgin/BrandLcs";
import Teams from "./desgin/Teams";
import Prize from "./desgin/Prize";
import Hzhb from "./desgin/Hzhb";
import Ziliao from "./factory/Ziliao";
import Sdrz from "./Sdrz";
import Stores from "./sale/Stores";
import Brands from "./sale/Brands";

export default {
  components: {
    State,
    Service,
    Xz,
    Contact,
    // Desgin,
    // Factory,
    Ziliao,
    Sdrz,
    // Sale,
    Hzhb,
    Cases,
    Stores,
    Brands,
    BrandLcs,
    Teams,
    Prize,
  },
  data() {
    return {
      aptitude: null,
      aptitude_score: null,
      // 资质审核状态，0未填写，1已提交，2审核通过，3审核不通过
      aptitude_stat: null,
      aptitude_msg: null,
      comp: this.$store.state.loginUser.comp,
    };
  },
  async created() {
    await this.refreshAptitude();
  },
  methods: {
    async refreshAptitude() {
      let resp = await getMyCompAptitude(this.$store.state.loginUser.comp.id);
      console.log(999, resp);
      const res = await getOrgidCapacity();
      if (res.success) {
        if (resp.aptitude.stat) {
          resp.aptitude.stat.capacity = res.content.capacity || "";
          resp.aptitude.stat.operators = res.content.operators || "";
        }
      }
      this.aptitude = Object.assign(
        { ability_service: [], real_check: {} },
        resp.aptitude
      );
      if (this.aptitude.relation)
        this.aptitude.relation.ability_make = this.aptitude.ability_make;
      this.aptitude_score = resp.aptitude_score;
      this.aptitude_stat = resp.aptitude_stat;
      this.aptitude_msg = resp.aptitude_msg;
    },
    cancel() {},
    reset() {
      this.refreshAptitude()
        .then(() => {
          this.$message.success("已重置");
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    async updateOrgidCapacity() {},
    async asyncSave() {
      await this.$refs.service.valid();
      await this.$refs.xz.valid();
      await this.$refs.contact.valid();

      // 设计公司
      if (this.$store.state.loginUser.comp.comp_type == 2) {
        await this.$refs.case.valid();
        await this.$refs.brandlcs.valid();
        await this.$refs.team.valid();

        await submitDesignAptitude(this.aptitude);
      }
      // 工厂
      if (this.$store.state.loginUser.comp.comp_type == 1) {
        await this.$refs.ziliao.valid();
        // await this.$refs.factory.valid();
        if (this.aptitude.relation)
          this.aptitude.ability_make =
            this.aptitude.relation.ability_make.filter((n) => {
              const fromMes = n.fromMes;
              delete n.fromMes;
              return !fromMes;
            });
        await submitFactoryAptitude(this.aptitude);
      }
      // 销售公司
      if (this.$store.state.loginUser.comp.comp_type == 3) {
        await this.$refs.store.valid();
        await this.$refs.brand.valid();
        await submitSaleAptitude(this.aptitude);
      }
      await updateOrgidCapacity({
        capacity: this.aptitude.stat.capacity, // 生产产能
        operators: this.aptitude.stat.operators, // 操作人员数量
      });
      this.aptitude = null;
      await this.refreshAptitude();
    },
    save() {
      this.asyncSave()
        .then(() => {
          console.log(this.aptitude);
          this.$message.success("保存成功");
        })
        .catch((err) => {
          console.log(err);
          if (err && err.message) this.$message.error(err.message);
        });
    },
  },
};
</script>
<style scoped>
.zt-block {
  margin: 24px 30px;
}
</style>
