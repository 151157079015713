<template>
    <div v-if="ruleForm">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px">
            <el-form-item label="店铺名称" prop="name">
                <el-input v-model="ruleForm.name" style="width:240px"></el-input>
            </el-form-item>
            <el-form-item label="渠道" prop="channel" required>
                <el-select v-model="ruleForm.channel" placeholder="请选择">
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value">
    </el-option>
  </el-select>
            </el-form-item>
            <el-form-item label="项目链接" prop="url">
                <el-input v-model="ruleForm.url"></el-input>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
    // import UploadImgs from '@/components/upload/UploadImgs.vue';
    export default {
        components:{
            // UploadImgs
        },
        props: ['case','formRef'],
        data() {
            return {
                options:[
                    {value: '淘宝',label: '淘宝'},
                    {value: '天猫',label: '天猫'}
                ],
                ruleForm: {},
                rules: {
                    name: [{ required: true, message: '请输入店铺名称', trigger: 'blur' }],
                    channel: [{ required: true, message: '请选择渠道', trigger: 'blur' }]
                    // photo: [{ required: true, message: '请输入商品价格', trigger: 'blur' }],
                    // url: [{ required: true, message: '请输入商品价格', trigger: 'blur' }],
                }
            }
        },
        watch: {
            
        },
        async created() {
            
        },
        mounted() {
            this.ruleForm = this.case;
            // Object.assign(this.ruleForm,this.item);
            // console.log("111",this.ruleForm);
            // console.log(this.item);
        },
        methods: {
            validForm() {
                return new Promise((resolve, reject) => {
                    this.$refs.ruleForm.validate((valid) => {
                        if (valid) {
                            resolve(true);
                        } else {
                            return reject();
                        }
                    });
                });
            }
        }
    }
</script>
<style scoped>

</style>