import { postToMesServer, postToMesServer2 } from './common';

/**
 * 企业端授权管理分页查询
 * @param {*} obj 
 */
export async function authPagingOrg(obj) {
  return await postToMesServer('/productmanagement/AdminOrg/PagingOrg', obj);
}
/**
 * 企业端日志管理分页查询
 * @param {*} obj 
 */
export async function authPagingOrgLog(obj) {
  return await postToMesServer('/productmanagement/AdminOrg/PagingOrgLog', obj);
}
/**
 * 改变授权状态
 * @param {*} obj 
 */
export async function updateAuthStatus(obj) {
  return await postToMesServer('/productmanagement/AdminOrg/Update', obj);
}
/**
 * admin日志列表
 * @param {*} obj 
 */
export async function authOrgLog(obj) {
  return await postToMesServer('/productmanagement/AdminOrg/PagingOrgLog', obj);
}

/**
 * 获取工序（工艺能力）
 * @param {*} obj 
 */
export async function getProcess(obj) {
  return await postToMesServer2('/productmanagement/BomProcess/GetOrgProcess', obj);
}

/**
 * 获取生产产能、操作人数
 * @param {*} obj 
 */
export async function getOrgidCapacity() {
  return await postToMesServer2('/productmanagement/ProManHour/GetOrgidCapacity');
}

/**
 * 修改生产产能、操作人数
 * @param {*} obj 
 */
export async function updateOrgidCapacity(obj) {
  return await postToMesServer2('/productmanagement/ProManHour/UpdateOrgidCapacity', obj);
}
