<template>
  <div class="zt-block">
    <div class="zt-block-head">公司现状</div>
    <div class="zt-block-content">
      <el-form
        v-if="ruleForm"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="150px"
      >
        <el-form-item label="总人数" prop="n_employee" required>
          <el-select
            v-model="ruleForm.n_employee"
            style="width: 240px"
            placeholder="请选择"
          >
            <el-option
              v-for="item in empOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="成立日期" prop="reg_date" required>
          <el-date-picker
            v-model="ruleForm.reg_date"
            type="date"
            value-format="timestamp"
            style="width: 240px"
          ></el-date-picker>
        </el-form-item>
        <template v-if="compType == 1">
          <el-form-item label="工厂面积" prop="area" required>
            <el-input v-model="ruleForm.area" style="width: 240px"
              ><template slot="append">㎡</template></el-input
            >
          </el-form-item>
          <el-form-item label="总产值" prop="amount">
            <el-input v-model="ruleForm.amount" style="width: 240px"
              ><template slot="append">万</template></el-input
            >
          </el-form-item>
          <el-form-item label="生产产能" prop="capacity">
            <el-input v-model="ruleForm.capacity" style="width: 240px"
              ><template slot="append">立方/月</template></el-input
            >
          </el-form-item>
          <el-form-item label="操作人员" prop="operators">
            <el-input v-model="ruleForm.operators" style="width: 240px"
              ><template slot="append">人</template></el-input
            >
          </el-form-item>
          <el-form-item label="生产设备" prop="n_equipment" required>
            <el-input v-model="ruleForm.n_equipment" style="width: 240px"
              ><template slot="append">台</template></el-input
            >
          </el-form-item>
          <el-form-item label="所在区域" prop="zone" required>
            <el-select
              v-model="ruleForm.zone"
              style="width: 240px"
              placeholder="请选择"
            >
              <el-option
                v-for="item in zoneOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="工厂图片" prop="photo" required>
            <UploadImgs
              v-model="ruleForm.photo"
              prefix="comp/aptitude/xz"
            ></UploadImgs>
          </el-form-item>
          <!-- <el-form-item label="工厂视频">
            <UploadImgs v-model="ruleForm.video"></UploadImgs>
          </el-form-item>-->
          <el-form-item label="工厂简介">
            <el-input
              type="textarea"
              :rows="8"
              v-model="ruleForm.intro"
            ></el-input>
          </el-form-item>
        </template>
        <!-- 设计公司 -->
        <template v-if="compType == 2">
          <el-form-item label="公司简介">
            <el-input
              type="textarea"
              v-model="ruleForm.intro"
              placeholder="一段文字介绍您的公司"
            ></el-input>
          </el-form-item>
        </template>
      </el-form>
    </div>
  </div>
</template>
<script>
import UploadImgs from "@/components/upload/UploadImgs.vue";
import { getFactoryZones } from "@/service/company";
export default {
  components: { UploadImgs },
  model: {
    prop: "xz",
    event: "change",
  },
  props: ["xz", "compType"],
  data() {
    return {
      ruleForm: null,
      empOptions: [
        {
          value: 1,
          label: "1-20人",
        },
        {
          value: 2,
          label: "21-50人",
        },
        {
          value: 3,
          label: "51-100人",
        },
        {
          value: 4,
          label: "101-200人",
        },
        {
          value: 5,
          label: "201-500人",
        },
        {
          value: 6,
          label: "501-1000人",
        },
        {
          value: 7,
          label: "1001人以上",
        },
      ],
      zoneOptions: [],
      rules: {
        n_employee: [
          { required: true, message: "请选择总人数", trigger: "blur" },
        ],
        reg_date: [
          { required: true, message: "请输入成立日期", trigger: "blur" },
        ],
        area: [{ required: true, message: "请输入工厂面积", trigger: "blur" }],
        // amount:[{ required: true, message: "请输入总产值", trigger: "blur" }],
        n_equipment: [
          { required: true, message: "请输入生产设备", trigger: "blur" },
        ],
        zone: [
          { required: true, message: "请选择工厂所在区域", trigger: "blur" },
        ],
        capacity: [
          { required: true, message: "请输入生产产能", trigger: "blur" },
        ],
        operators: [
          { required: true, message: "请输入操作人员", trigger: "blur" },
        ],
        photo: [{ required: true, message: "请上传工厂图片", trigger: "blur" }],
        // intro: [{ required: true, message: '请输入项目描述', trigger: 'blur' }],
        // photo: [{ required: true, message: '请输入商品价格', trigger: 'blur' }],
        // url: [{ required: true, message: '请输入商品价格', trigger: 'blur' }],
      },
    };
  },
  watch: {
    // "xz":{
    //   handler(newName, oldName) {
    //     Object.assign(this.ruleForm,this.xz);
    //   },
    //   deep:true
    // }
  },
  async created() {
    // Object.assign(this.ruleForm,this.xz);
    // console.log(123,this.ruleForm,this.xz);
    this.ruleForm = this.xz || {};
    this.zoneOptions = await getFactoryZones();
  },
  // mounted() {
  //   // this.ruleForm = this.xz || {};
  //   // Object.assign(this.ruleForm,this.item);
  //   // console.log("111",this.ruleForm);
  //   // console.log(this.item);
  // },
  methods: {
    valid() {
      return new Promise((resolve, reject) => {
        this.$refs.ruleForm.validate((valid, obj) => {
          if (valid) {
            this.$emit("change", this.ruleForm);
            resolve(true);
          } else {
            return reject(new Error(obj[Object.keys(obj)[0]][0].message));
          }
        });
      });
    },
  },
};
</script>
<style scoped>
.zt-block {
  margin: 24px 30px;
}
</style>
