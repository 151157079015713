<template>
  <div
    class="flex"
    style="border: 1px solid rgba(204, 204, 204, 1); border-radius: 4px"
  >
    <div
      style="padding: 10px; border-right: 1px solid #eee; min-height: 220px"
      class="flex-1"
    >
      <template v-for="(tag, idx) in allGynls">
        <el-tag
          v-if="tag.selected"
          :key="idx"
          :disable-transitions="false"
          @click="selectTag(idx)"
          @close="handleClose(idx)"
          class="tag"
          :type="tag.fromMes ? '' : 'success'"
          :effect="tag.id == curNl.id ? 'dark' : 'plain'"
          :closable="tag.fromMes === undefined"
          ><span
            @dblclick="handleDbClick(tag)"
            style="
              width: 100%;
              height: 100%;
              display: inline-block;
              user-select: none;
            "
            >{{ tag.name }}</span
          ></el-tag
        >
      </template>
      <el-button
        type="text"
        size="mini"
        round
        @click="popSelect"
        icon="el-icon-plus"
        >添加工艺</el-button
      >
      <!-- 弹出框 -->
      <el-dialog
        title="添加工艺"
        :visible.sync="popVisible"
        :destroy-on-close="true"
      >
        <div style="font-weight: 500; text-align: center">
          <div>
            <el-checkbox
              v-for="(item, idx) in allGynls"
              :key="idx"
              v-model="item.selected"
              @change="$set(allGynls, idx, allGynls[idx])"
              >{{ item.name }}</el-checkbox
            >
            <el-button
              type="text"
              size="mini"
              round
              icon="el-icon-plus"
              @click="popCustom"
              >添加自定义工艺</el-button
            >
          </div>
          <!-- <el-divider></el-divider>
          <div>
            <el-button type="primary" @click="popVisible=false" plain>取消</el-button>
            <el-button type="primary" @click="confrim">确认</el-button>
          </div> -->
        </div>
      </el-dialog>
      <!-- 自定义工艺弹出框 -->
      <el-dialog
        title="自定义工艺"
        :visible.sync="custGynlVisible"
        :destroy-on-close="true"
        width="300px"
      >
        <CustGynl
          @change="addCustom"
          @cancel="custGynlVisible = false"
        ></CustGynl>
      </el-dialog>
    </div>
    <div v-if="curNl && curNl.id" class="edit-nl">
      <div
        style="
          width: 80px;
          font-size: 18px;
          font-weight: bold;
          text-align: right;
        "
      >
        {{ curNl.name }}
      </div>
      <div v-for="(child, idx) in curNl.quota" :key="idx" class="flex option">
        <div style="width: 80px; text-align: right; margin-right: 16px">
          {{ child.name }}
        </div>
        <!-- 配置项类型，1单选，2多选，3文本，4整数，5小数，6百分比 -->
        <div v-if="child.type == 1 || child.type == 2">
          <el-select
            :multiple="child.type == 2"
            v-model="child.value"
            placeholder="请选择"
            @change="onCurNlChange"
          >
            <el-option
              v-for="(item, oidx) in child.options"
              :key="oidx"
              :label="item.v"
              :value="item.k"
            ></el-option>
          </el-select>
        </div>
        <div v-if="child.type == 3">
          <el-input
            v-model="child.value"
            placeholder="请输入文本"
            @input="onCurNlChange"
          ></el-input>
        </div>
        <div v-if="child.type == 4">
          <el-input
            v-model="child.value"
            type="number"
            placeholder="请输入整数"
            min="0"
            @input="onCurNlChange"
          ></el-input>
        </div>
        <div v-if="child.type == 5">
          <el-input
            v-model="child.value"
            type="number"
            placeholder="请输入小数"
            min="0"
            @input="onCurNlChange"
          ></el-input>
        </div>
        <div v-if="child.type == 6">
          <el-input
            v-model="child.value"
            type="number"
            placeholder="请输入百分比"
            min="0"
            @input="onCurNlChange"
          ></el-input>
        </div>
      </div>
      <div style="text-align: center">
        <el-checkbox v-model="curNl.primary">主要工艺</el-checkbox>
      </div>
    </div>
  </div>
</template>
<script>
import cloneDeep from "lodash/cloneDeep";
import { getFactoryMakeAbility } from "@/service/company";
import CustGynl from "./CustGynl";
import { getProcess } from "@/service/mes";
export default {
  components: { CustGynl },
  model: {
    prop: "ability_make",
    event: "change",
  },
  props: ["ability_make"],
  data() {
    return {
      mesProcess: [],
      allGynls: [],
      // selectedGynls: [],
      // ability_make:[],
      curNl: {},
      popVisible: false,
      custGynlVisible: false,
    };
  },
  watch: {
    allGynls: {
      handler(newName, oldName) {
        console.log(55555555, newName);
        this.syncDate();
      },
      deep: true,
    },
    // "curNl":{
    //   handler(newName, oldName) {console.log(66666666,newName);
    //     this.syncDate();
    //   },
    //   deep:true
    // }
  },
  computed: {
    loginUser() {
      return this.$store.state.loginUser;
    },
  },
  async created() {},
  async mounted() {
    // 测试数据
    // this.selectedGynls = [
    //   {
    //     id: "30d19ab50c0e4e14aa683e57696d01f1",
    //     name: "拼版",
    //     primary: true,
    //     quota: [
    //       {
    //         id: "e271f4bb0904427785e919e77e1af894",
    //         name: "黏合度",
    //         value: "1583835920166"
    //       },
    //       {
    //         id: "ea272aba255647b396036f0a04967954",
    //         name: "纹理色泽",
    //         value: ["1583835743189"]
    //       }
    //     ]
    //   }
    // ];
    this.allGynls = (await getFactoryMakeAbility()) || [];

    // 添加自定义能力
    for (let am of this.ability_make) {
      if (am.id < 0) {
        if (am.quota && am.quota.length > 0) {
          for (let _quota of am.quota) {
            // 设置为文本类型
            _quota.type = 3;
          }
        }
        this.allGynls.push(am);
      }
    }
    const res = await getProcess({
      tenantId: 0,
      orgId: this.loginUser.user.comp_id,
    });
    if (res.success) {
      this.mesProcess = res.content.processNames.split(",");
      this.mesProcess.map((n, i) => {
        const find = this.allGynls.find((m) => m.name === n);
        if (!find) {
          this.allGynls.push({
            id: "-" + new Date().getTime() + "" + i - 0,
            name: n,
            fromMes: true,
            quota: [],
            selected: true,
          });
        }
      });
    }
    this.syncSelected();
    this.syncDate();
  },
  methods: {
    handleDbClick(tag) {
      console.log(tag);
      if (tag.fromMes === undefined) {
        return;
      } else {
        tag.fromMes = !tag.fromMes;
      }
    },
    syncSelected() {
      if (this.allGynls && this.allGynls.length > 0) {
        for (let Gynl of this.allGynls) {
          // 已选中的能力
          let exist = this.ability_make.find((r) => {
            return r.id == Gynl.id;
          });
          if (exist) {
            Gynl.selected = true;
            Gynl.primary = exist.primary;
            if (Gynl.quota && Gynl.quota.length > 0) {
              for (let quota_ of Gynl.quota) {
                // 被设置过值得指标
                let existQuota = exist.quota.find((r) => {
                  return r.id == quota_.id;
                });
                if (existQuota) {
                  quota_.value = existQuota.value;
                }
              }
            }
          }
        }
      }
      // console.log(this.allGynls,this.ability_make);
      this.$forceUpdate();
    },
    handleClose(idx) {
      let item = this.allGynls[idx];
      if (item.selected == true) {
        this.curNl = {};
        item.selected = false;
      }
      this.$set(this.allGynls, idx, item);
    },
    selectTag(idx) {
      this.curNl = this.allGynls[idx];
    },
    popSelect() {
      this.popVisible = true;
    },
    syncDate() {
      let rst = this.allGynls.reduce((pre, cur) => {
        if (cur.selected == true) {
          let cloneV = cloneDeep(cur);
          delete cloneV.selected;
          if (cloneV.quota && cloneV.quota.length > 0) {
            for (let quota_ of cloneV.quota) {
              delete quota_.options;
              delete quota_.type;
            }
          }
          pre.push(cloneV);
        }
        return pre;
      }, []);
      this.$emit("change", rst);
    },
    onCurNlChange() {
      this.$forceUpdate();
      this.syncDate();
    },
    popCustom() {
      this.popVisible = false;
      this.custGynlVisible = true;
    },
    addCustom(item) {
      console.log(item);
      item.selected = true;
      this.curNl = item;
      this.allGynls.push(item);
      this.custGynlVisible = false;
    },
  },
};
</script>
<style scoped>
.el-tag {
  width: 76px;
  text-align: center;
  position: relative;
}

/* .el-tag--dark {
  background: linear-gradient(
    180deg,
    rgba(124, 191, 246, 1) 0%,
    rgba(39, 147, 230, 1) 100%
  );
} */
.el-tag >>> .el-tag__close {
  position: absolute;
  right: -5px;
  top: -5px;
  border-radius: 50%;
  border: 1px solid #808080;
  color: #808080;
  background-color: #fff;
}
.edit-nl {
  background: rgba(251, 248, 248, 1);
  padding: 12px 20px;
  margin: 8px;
  color: rgba(128, 128, 128, 1);
  min-width: 400px;
}
.option:not(:first-child) {
  margin-top: 16px;
}
.option > div:last-child {
  width: 200px;
}
.tag:hover {
  cursor: pointer;
}
.tag:not(:last-child) {
  margin-right: 58px;
}
.edit-nl >>> input[type="number"] {
  padding: 0 0 0 15px;
}
</style>