<template>
    <div v-if="ruleForm">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px">
            <el-form-item label="品牌名称" prop="name">
                <el-input v-model="ruleForm.name" style="width:240px"></el-input>
            </el-form-item>
            <el-form-item label="品牌证书">
                <UploadImgs v-model="ruleForm.photo" prefix='comp/aptitude/brand'></UploadImgs>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
    import UploadImgs from '@/components/upload/UploadImgs.vue';
    export default {
        components:{
            UploadImgs
        },
        props: ['case','formRef'],
        data() {
            return {
                ruleForm: null,
                rules: {
                    name: [{ required: true, message: '请输入品牌名称', trigger: 'blur' }],
                    // bg: [{ required: true, message: '请输入项目背景', trigger: 'blur' }],
                    // intro: [{ required: true, message: '请输入项目描述', trigger: 'blur' }],
                    // photo: [{ required: true, message: '请输入商品价格', trigger: 'blur' }],
                    // url: [{ required: true, message: '请输入商品价格', trigger: 'blur' }],
                }
            }
        },
        watch: {
            
        },
        async created() {
            
        },
        mounted() {
            this.ruleForm = this.case;
            // Object.assign(this.ruleForm,this.item);
            // console.log("111",this.ruleForm);
            // console.log(this.item);
        },
        methods: {
            validForm() {
                return new Promise((resolve, reject) => {
                    this.$refs.ruleForm.validate((valid) => {
                        if (valid) {
                            resolve(true);
                        } else {
                            return reject();
                        }
                    });
                });
            }
        }
    }
</script>
<style scoped>

</style>